<template>
	<div class="user-update-form">
		<a-form layout="horizontal" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
			<a-form-item label="ID">
				<a-input v-model="user.id" disabled />
			</a-form-item>
			<a-form-item label="姓名">
				<a-input v-model="realNameInput" placeholder="请填写姓名" allow-clear />
			</a-form-item>
			<a-form-item label="手机号">
				<a-input v-model="usernameInput" placeholder="请填写手机号" allow-clear />
			</a-form-item>
			<a-form-item label="备注">
				<a-input v-model="remarkInput" placeholder="请填写备注" allow-clear />
			</a-form-item>
			<a-form-item :wrapper-col="{ span: 12, offset: 6 }">
				<a-button type="primary" html-type="submit" @click="onSubmit">
					提交
				</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
	import UmsUserApi from "@/api/ums/ums-user.js";

	export default {
		name: "UserUpdateForm",
		props: ["user"],
		data() {
			return {
				usernameInput: "",
				realNameInput: "",
				remarkInput: "",
			};
		},
		watch: {
			"user.id": function(val) {
				this.onInit();
			}
		},
		mounted: function() {
			this.onInit();
		},
		methods: {
			onInit: function() {
				this.usernameInput = this.user.username
				this.realNameInput = this.user.realName
				this.remarkInput = this.user.remark
			},
			onSubmit: function() {
				const payload = {
					id: this.user.id,
					username: this.usernameInput,
					realName: this.realNameInput,
					remark: this.remarkInput,
				};
				UmsUserApi.update(payload).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success(response.message);
						this.$emit("onSubmit", response);
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped></style>
