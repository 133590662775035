<template>
  <div class="user-create-form">
    <a-form
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-item label="姓名">
        <a-input v-model="realName" placeholder="请填写姓名" allow-clear/>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input v-model="username" placeholder="请填写手机号" allow-clear/>
      </a-form-item>
      <a-form-item label="密码">
        <a-input v-model="password" placeholder="请填写密码" allow-clear/>
      </a-form-item>
      <a-form-item label="备注">
        <a-input v-model="remark" placeholder="请填写备注" allow-clear/>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button type="primary" html-type="submit" @click="onSubmit">
          提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import UmsUserApi from "@/api/ums/ums-user.js";

export default {
  name: "UserCreateForm",
  data() {
    return {
      username: "",
      password: "",
      realName: "",
      remark: "",
    };
  },
  mounted: function () {},
  methods: {
    onSubmit: function () {
      const payload = {
        username: this.username,
        password: CryptoJS.MD5(this.password).toString(),
        realName: this.realName,
        remark: this.remark,
        status: 0,
      };
      UmsUserApi.create(payload).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.$emit("onSubmit", response);
          Object.assign(this.$data, this.$options.data());
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
