import http from "@/api/http.js";

function listByAppId(id) {
  return http({
    url: "/SysAppMenu/ListByAppId/" + id,
    method: "get"
  });
}

function create(payload) {
  return http({
    url: "/SysAppMenu/Create",
    method: "post",
    data: payload
  });
}

export default {
  listByAppId,
  create
};
