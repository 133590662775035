<template>
  <div class="sys-app-relate-transfer">
    <a-transfer
      class="relate-transfer"
      :data-source="dataSourceList"
      :titles="['未关联', '已关联']"
      :target-keys="targetKeyList"
      :render="(item) => item.title"
      :listStyle="{ width: '40%', height: '360px' }"
      @change="handleTransferChange"
    >
    </a-transfer>
  </div>
</template>

<script>
import SysAppApi from "@/api/sys/sys-app.js";
import UmsUserApi from "@/api/ums/ums-user.js";

export default {
  name: "SysAppRelateTransfer",
  props: ["user"],
  data() {
    return {
      dataSourceList: [],
      targetKeyList: [],
      selectedKeyList: []
    };
  },
  watch: {
    user: function(val, oldVal) {
      // this.handleSysAppList();
      this.handleUmsUserSysAppList();
    }
  },
  mounted: function() {
    this.handleSysAppList();
    if (this.user) this.handleUmsUserSysAppList();
  },
  methods: {
    handleSysAppList: function() {
      SysAppApi.listAll().then((response) => {
        if (response.status === "SUCCESS") {
          this.dataSourceList = response.body || [];
          this.dataSourceList.forEach((item) => {
            this.$set(item, "key", item.id);
            this.$set(item, "title", item.name);
          });
        } else {
          this.dataSourceList = [];
        }
      });
    },
    handleUmsUserSysAppList: function() {
      UmsUserApi.listSysAppByUmsUserId(this.user.id).then((response) => {
        this.targetKeyList = [];
        if (response.status === "SUCCESS") {
          response.body.forEach((item) => {
            this.targetKeyList.push(item.sysAppId);
          });
        }
      });
    },
    handleTransferChange: function(nextTargetKeys, direction, moveKeys) {
      if (direction === "right")
        this.handleUmsUserSysAppRelate(nextTargetKeys, moveKeys);
      if (direction === "left")
        this.handleUmsUserSysAppRemove(nextTargetKeys, moveKeys);
    },
    handleUmsUserSysAppRelate: function(nextTargetKeys, moveKeys) {
      var payload = [];
      moveKeys.forEach(item => {
        payload.push({ umsUserId: this.user.id, sysAppId: item });
      });
      UmsUserApi.relateSysApp(payload).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.targetKeyList = nextTargetKeys;
        }
      });
    },
    handleUmsUserSysAppRemove: function(nextTargetKeys, moveKeys) {

      UmsUserApi.removeSysApp(this.user.id, moveKeys).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.targetKeyList = nextTargetKeys;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.relate-transfer {
  width: 100%;
}
</style>
