<template>
	<div class="ums-user-permission-table">
		<div class="header">
			<a-button type="primary" @click="handleRefreshBtnClick">刷新</a-button>
		</div>
		<div class="body">
			<a-table rowKey="id" :data-source="getCurrentDataSourceList" :pagination="paginationOptions"
				@change="(pagination) => {paginationOptions.current = pagination.current;paginationOptions.pageSize = pagination.pageSize;}"
				:scroll="{ x: true }" bordered size="small">
				<a-table-column title="序号" data-index="index" align="center">
					<template slot-scope="text, record, index">
						<span>{{(paginationOptions.current - 1) * paginationOptions.pageSize + parseInt(index) + 1}}</span>
					</template>
				</a-table-column>
				<a-table-column key="appName" title="应用名称" width="200px" data-index="appName">
					<template slot-scope="text, record">
						<div>{{record.sysApp.name}}</div>
					</template>
				</a-table-column>
				<a-table-column key="appMenuName" title="应用菜单" width="200px" data-index="appMenuName">
					<template slot-scope="text, record">
						<div>{{record.sysAppMenu.name}}</div>
					</template>
				</a-table-column>
				<a-table-column key="organizationNumber" title="组织编号" width="200px" data-index="organizationNumber">
					<template slot-scope="text, record">
						<div>{{record.umsOrganization.number}}</div>
					</template>
				</a-table-column>
				<a-table-column key="organizationName" title="组织名称" width="300px" data-index="organizationName">
					<template slot-scope="text, record">
						<div>{{record.umsOrganization.name}}</div>
					</template>
				</a-table-column>
				<a-table-column key="remark" title="备注" width="200px" data-index="remark" />
				<a-table-column title="操作" data-index="operation" fixed="right">
					<template slot-scope="text, record">
						<a-popconfirm title="是否确定删除此权限？" ok-text="是" cancel-text="否"
							@confirm="handleDataSourceDelete(record)">
							<a href="#">删除</a>
						</a-popconfirm>
						<!-- <a-button type="link" size="small" @click="handleDataSourceDelete(record)">删除</a-button> -->
					</template>
				</a-table-column>
			</a-table>
		</div>
	</div>
</template>

<script>
	import PaginationOptions from "@/options/pagination.js";
	import FiltrationOptions from "@/options/filtration.js";
	import UmsUserPermissionApi from "@/api/ums/ums-user-permission.js";

	export default {
		name: "UmsUserPermissionTable",
		props: ["user"],
		data() {
			return {
				paginationOptions: PaginationOptions,
				filtrationOptions: FiltrationOptions,
				queryType: "1",
				queryDate: [],
				dataSourceList: [],
				dataSourceCurrent: {},
				dataSourceSearchInput: "",
				dataSourceSearchList: [],
				dataSourceSearchValid: false,
			}
		},
		computed: {
			getCurrentDataSourceList: function() {
				return this.dataSourceSearchValid ?
					this.dataSourceSearchList :
					this.dataSourceList;
			}
		},
		watch: {
			dataSourceSearchInput: function(val) {
				if (val === null || val === "") {
					this.dataSourceSearchList = [];
					this.dataSourceSearchValid = false;
				}
			},
			"user.id": function(val) {
				this.fetchUserPermission(this.user.id)
			}
		},
		mounted: function() {
			this.fetchUserPermission(this.user.id)
		},
		methods: {
			fetchUserPermission: function(userId) {
				UmsUserPermissionApi.listByUserId(userId).then(response => {
					if (response.status === "SUCCESS") {
						this.dataSourceList = response.body;
						if (this.dataSourceList.length === 0)
							this.$message.success("无数据");
					} else {
						this.dataSourceList = [];
					}
				})
			},
			handleRefreshBtnClick: function() {
				this.fetchUserPermission(this.user.id);
			},
			handleDataSourceDelete: function(record) {
				UmsUserPermissionApi.removeById(record.id).then(response => {
					if (response.status === "SUCCESS") {
						this.$message.success("删除成功！");
						this.fetchUserPermission(this.user.id);
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.header {
		margin-bottom: 20px;
	}
</style>
