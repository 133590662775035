<template>
	<div class="user-permission-form">
		<a-form layout="horizontal" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
			<a-form-item label="用户信息">
				<div>{{ user.username }} {{ user.realName }}</div>
			</a-form-item>

			<a-form-item label="应用信息">
				<a-select v-model="appSelectValue" placeholder="请选择应用信息" :options="appSelectOptions"
					:loading="appSelectLoading" @change="handleAppSelectChange"></a-select>
				<a-select v-model="appMenuSelectValue" placeholder="请选择应用菜单信息" :options="appMenuSelectOptions"
					:loading="appMenuSelectLoading"></a-select>
			</a-form-item>

			<a-form-item label="组织信息">
				<a-cascader v-model="organizationCascaderValue" placeholder="请选择组织信息" change-on-select
					:loading="organizationCascaderLoading" :options="organizationCascaderOptions"
					:field-names="{ label: 'name', value: 'id', children: 'children' }"
					@change="handleOrganizationCascaderChange" />
				<div style="text-align: right">
					<a-button type="primary" @click="handleOrganizationListAdd">添加</a-button>
				</div>
				<a-row v-for="(item, index) in organizationList" :key="index">
					<a-col :span="14">{{ index + 1 }}.{{ item.fullName }}</a-col>
					<a-col :span="8" style="text-align: right;">{{ item.number }}</a-col>
					<a-col :span="2" style="text-align: right;">
						<a-button type="link" size="small" @click="organizationList.splice(index,1)">删除</a-button>
					</a-col>
				</a-row>
			</a-form-item>

			<a-form-item label="备注">
				<a-input v-model="remarkInput" placeholder="请填写备注" allow-clear />
			</a-form-item>

			<a-form-item :wrapper-col="{ span: 12, offset: 6 }">
				<a-button type="primary" html-type="submit" @click="onSubmit">
					提交
				</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
	import SysAppApi from "@/api/sys/sys-app.js";
	import SysAppMenuApi from "@/api/sys/sys-app-menu.js";
	import UmsOrganizationApi from "@/api/ums/ums-organization.js";
	import UmsUserPermissionApi from "@/api/ums/ums-user-permission.js";

	export default {
		name: "UserPermissionForm",
		props: {
			user: Object,
		},
		data() {
			return {
				appSelectValue: [],
				appSelectOptions: [],
				appSelectLoading: false,
				appMenuSelectValue: [],
				appMenuSelectOptions: [],
				appMenuSelectLoading: false,
				organizationCascaderValue: [],
				organizationCascaderSelectedOptions: [],
				organizationCascaderOptions: [],
				organizationCascaderLoading: false,
				organizationList: [],
				remarkInput: "",
			};
		},
		watch: {
			"user.id": function(val, oldVal) {
				this.appSelectValue = [];
				this.appMenuSelectValue = [];
			},
		},
		mounted: function() {
			this.fetchAppSelectOptions();
			this.fetchOrganizationTree();
		},
		methods: {
			fetchAppSelectOptions: function() {
				this.appSelectLoading = true;
				SysAppApi.listAll().then((response) => {
					this.appSelectLoading = false;
					this.appSelectOptions = [];
					if (response.status === "SUCCESS") {
						response.body.forEach((item) => {
							this.appSelectOptions.push({
								value: item.id,
								label: item.name,
							});
						});
					}
				});
			},
			handleAppSelectChange: function(value, option) {
				this.fetchAppMenuSelectOptions(value);
			},
			fetchAppMenuSelectOptions: function(appId) {
				this.appMenuSelectLoading = true;
				SysAppMenuApi.listByAppId(appId).then((response) => {
					this.appMenuSelectLoading = false;
					this.appMenuSelectOptions = [];
					if (response.status === "SUCCESS") {
						response.body.forEach((item) => {
							this.appMenuSelectOptions.push({
								value: item.id,
								label: item.name,
							});
						});
					}
				});
			},
			fetchOrganizationTree: function() {
				this.organizationCascaderLoading = true;
				UmsOrganizationApi.listTree().then((response) => {
					this.organizationCascaderLoading = false;
					if (response.status === "SUCCESS") {
						this.organizationCascaderOptions = response.body;
					} else {
						this.organizationCascaderOptions = [];
					}
				});
			},
			handleOrganizationCascaderChange: function(value, selectedOptions) {
				this.organizationCascaderSelectedOptions = selectedOptions;
			},
			handleOrganizationListAdd: function() {
				if (this.organizationCascaderSelectedOptions.length > 0) {
					let payload = {
						id: "",
						number: "",
						fullName: "",
					};
					for (let i = 0, j = this.organizationCascaderSelectedOptions.length; i < j; i++) {
						payload.fullName += this.organizationCascaderSelectedOptions[i].name;
						if (i !== j - 1) {
							payload.fullName += " / ";
						}
						if (i === j - 1) {
							payload.id = this.organizationCascaderSelectedOptions[i].id;
							payload.number = this.organizationCascaderSelectedOptions[i].number;
						}
					}
					const result = this.organizationList.filter(item => {
						return item.id === payload.id
					})
					if (result.length === 0) {
						this.organizationList.push(payload);
					} else {
						this.$message.error("已存在该组织信息，请勿重复添加！");
					}
				}
			},
			onSubmit: function() {
				if (!this.appSelectValue || !this.appMenuSelectValue) {
					this.$message.error("请选择应用信息及应用菜单信息后再提交！");
					return
				}
				if (this.organizationList.length === 0) {
					this.$message.error("请添加组织架构信息后再提交！");
					return
				}

				let payload = {
					userId: this.user.id,
					sysAppId: this.appSelectValue,
					sysAppMenuId: this.appMenuSelectValue,
					organizationIdList: [],
					remark: this.remarkInput,
				}
				this.organizationList.forEach(item => {
					payload.organizationIdList.push(item.id);
				})

				UmsUserPermissionApi.create(payload).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success(response.message);
						this.$emit("onSubmit", response);
						this.onClearData();
					}
				})
			},
			onClearData: function() {
				this.appSelectValue = [];
				this.appMenuSelectValue = [];
				this.organizationCascaderValue = [];
				this.organizationCascaderSelectedOptions = [];
				this.organizationList = [];
				this.remarkInput = "";
			}
		},
	};
</script>

<style lang="scss" scoped></style>
