<template>
	<div class="ums-user">
		<div class="header">
			<a-breadcrumb style="margin-bottom: 20px">
				<a-breadcrumb-item>用户中心</a-breadcrumb-item>
				<a-breadcrumb-item>系统用户</a-breadcrumb-item>
			</a-breadcrumb>
			<a-row>
				<a-col :span="18">
					<a-button type="primary" @click="userCreateModalVisible = true">新增
					</a-button>
				</a-col>
				<a-col :span="6">
					<a-input-search v-model="dataSourceSearchInput" placeholder="请输入任何想搜索的内容" allowClear
						@search="handleDataSourceSearch" />
				</a-col>
			</a-row>
		</div>
		<div class="body">
			<a-table rowKey="id" :data-source="getCurrentDataSourceList" :pagination="paginationOptions"
				@change="(pagination) => {paginationOptions.current = pagination.current;paginationOptions.pageSize = pagination.pageSize;}"
				:scroll="{ x: true }" bordered size="small">
				<a-table-column title="序号" data-index="index" align="center">
					<template slot-scope="text, record, index">
						<span>{{(paginationOptions.current - 1) * paginationOptions.pageSize + parseInt(index) + 1}}</span>
					</template>
				</a-table-column>
				<a-table-column key="id" title="ID" width="200px" data-index="id" />
				<a-table-column key="username" title="用户名" width="150px" data-index="username" />
				<a-table-column key="realName" title="姓名" width="100px" data-index="realName" />
				<a-table-column key="remark" title="备注" width="200px" data-index="remark" />
				<a-table-column key="status" title="状态" width="100px" data-index="status" fixed="right" align="center">
					<template slot-scope="text, record">
						<a-switch checked-children="启用" un-checked-children="禁用" :checked="text === 1"
							@change="handleStatusSwitchChange(record)" />
					</template>
				</a-table-column>
				<a-table-column title="权限管理" data-index="permission" fixed="right" align="center">
					<template slot-scope="text, record">
						<a-button type="link" size="small"
							@click="dataSourceCurrent = record;userPermissionTableModalVisible = true;">查看</a-button>
						<a-button type="link" size="small"
							@click="dataSourceCurrent = record;userPermissionFormModalVisible = true;">配置</a-button>
					</template>
				</a-table-column>
				<a-table-column title="操作" data-index="operation" fixed="right" align="center">
					<template slot-scope="text, record">
						<a-button type="link" size="small"
							@click="dataSourceCurrent = record;userUpdateModalVisible = true;">资料修改</a-button>
						<a-button type="link" size="small"
							@click="dataSourceCurrent = record;sysAppRelateModalVisible = true;">关联应用</a-button>
						<a-button type="link" size="small"
							@click="dataSourceCurrent = record;pmsErpRelateModalVisible = true;">关联ERP</a-button>
						<a-popconfirm title="是否重置此用户密码？" ok-text="是" cancel-text="否"
							@confirm="handleDataSourcePasswordReset(record)">
							<a-button type="link" size="small">密码重置</a-button>
						</a-popconfirm>
					</template>
				</a-table-column>
			</a-table>
		</div>
		<div class="footer"></div>

		<a-modal v-model="userCreateModalVisible" title="请填写以下信息" closable :footer="null">
			<user-create-form @onSubmit="handleUserCreateFormSubmit" />
		</a-modal>

		<a-modal v-model="userUpdateModalVisible" title="请填写以下信息" closable :footer="null">
			<user-update-form :user="dataSourceCurrent" @onSubmit="handleUserUpdateFormSubmit" />
		</a-modal>

		<a-modal v-model="sysAppRelateModalVisible" title="关联应用信息" closable :footer="null"
			:width="screenSpec.width - 2 * 100">
			<sys-app-relate-transfer :user="dataSourceCurrent" />
		</a-modal>

		<a-modal v-model="pmsErpRelateModalVisible" title="关联ERP信息" closable :footer="null"
			:width="screenSpec.width - 2 * 100">
			<pms-erp-relate-transfer :user="dataSourceCurrent" />
		</a-modal>

		<a-modal v-model="userPermissionTableModalVisible" title="权限查看" closable :footer="null"
			:width="screenSpec.width - 2 * 100">
			<user-permission-table :user="dataSourceCurrent" />
		</a-modal>

		<a-modal v-model="userPermissionFormModalVisible" title="权限配置" closable :footer="null"
			:width="screenSpec.width - 2 * 100">
			<user-permission-form :user="dataSourceCurrent" @onSubmit="userPermissionFormModalVisible=false" />
		</a-modal>
	</div>
</template>

<script>
	import PaginationOptions from "@/options/pagination.js";
	import FiltrationOptions from "@/options/filtration.js";
	import UmsUserApi from "@/api/ums/ums-user.js";
	import {
		mapState
	} from "vuex";
	import SysAppRelateTransfer from "./components/SysAppRelateTransfer.vue";
	import PmsErpRelateTransfer from "./components/PmsErpRelateTransfer.vue";
	import UserCreateForm from "./components/UserCreateForm.vue";
	import UserUpdateForm from "./components/UserUpdateForm.vue";
	import UserPermissionForm from "./components/UserPermissionForm.vue";
	import UserPermissionTable from "./components/UserPermissionTable.vue";

	export default {
		components: {
			SysAppRelateTransfer,
			PmsErpRelateTransfer,
			UserCreateForm,
			UserUpdateForm,
			UserPermissionForm,
			UserPermissionTable
		},
		name: "UmsUser",
		data() {
			return {
				paginationOptions: PaginationOptions,
				filtrationOptions: FiltrationOptions,
				queryType: "1",
				queryDate: [],
				dataSourceList: [],
				dataSourceCurrent: {},
				dataSourceSearchInput: "",
				dataSourceSearchList: [],
				dataSourceSearchValid: false,
				sysAppRelateModalVisible: false,
				pmsErpRelateModalVisible: false,
				userCreateModalVisible: false,
				userUpdateModalVisible: false,
				userPermissionFormModalVisible: false,
				userPermissionTableModalVisible: false
			};
		},
		computed: {
			...mapState({
				screenSpec: (state) => state.screenSpec
			}),
			getCurrentDataSourceList: function() {
				return this.dataSourceSearchValid ?
					this.dataSourceSearchList :
					this.dataSourceList;
			}
		},
		watch: {
			dataSourceSearchInput: function(val) {
				if (val === null || val === "") {
					this.dataSourceSearchList = [];
					this.dataSourceSearchValid = false;
				}
			}
		},
		mounted: function() {
			this.fetchUserList();
		},
		methods: {
			fetchUserList: function() {
				UmsUserApi.listAll().then((response) => {
					if (response.status === "SUCCESS") {
						this.dataSourceList = response.body;
						if (this.dataSourceList.length === 0) this.$message.success("无数据");
					} else {
						this.dataSourceList = [];
					}
				});
			},
			handleTableChange(pagination) {
				this.paginationOptions.current = pagination.current;
				this.paginationOptions.pageSize = pagination.pageSize;
			},
			handleDataSourceSearch: function(value) {
				if (value === null || value === "") {
					this.dataSourceSearchList = [];
					this.dataSourceSearchValid = false;
					return;
				}
				this.dataSourceSearchList = this.dataSourceList.filter((item) => {
					return JSON.stringify(item).indexOf(value.trim()) !== -1;
				});
				this.dataSourceSearchValid = true;
			},
			handleUserCreateFormSubmit: function(response) {
				this.userCreateModalVisible = false;
				if (response && response.status === "SUCCESS") {
					this.fetchUserList();
				}
			},
			handleUserUpdateFormSubmit: function(response) {
				this.userUpdateModalVisible = false;
				if (response && response.status === "SUCCESS") {
					this.fetchUserList();
				}
			},
			handleStatusSwitchChange: function(record) {
				UmsUserApi.updateStatus(record.id).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success("更新成功");
						this.fetchUserList();
					}
				});
			},
			handleDataSourcePasswordReset: function(record) {
				UmsUserApi.resetPassword(record.id).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success(response.message);
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.header {
		margin-bottom: 20px;
	}
</style>
