<template>
  <div class="pms-erp-relate-transfer">
    <a-transfer
      class="relate-transfer"
      :data-source="dataSourceList"
      :titles="['未关联', '已关联']"
      :target-keys="targetKeyList"
      :render="(item) => item.title"
      :listStyle="{ width: '40%', height: '360px' }"
      @change="handleTransferChange"
    >
    </a-transfer>
  </div>
</template>

<script>
import PmsErpApi from "@/api/pms/pms-erp.js";
import UmsUserApi from "@/api/ums/ums-user.js";

export default {
  name: "PmsErpRelateTransfer",
  props: ["user"],
  data() {
    return {
      dataSourceList: [],
      targetKeyList: [],
      selectedKeyList: [],
    };
  },
  watch: {
    user: function (val, oldVal) {
      this.handleUmsUserPmsErpList();
    },
  },
  mounted: function () {
    this.handlePmsErpList();
    if (this.user) this.handleUmsUserPmsErpList();
  },
  methods: {
    handlePmsErpList: function () {
      PmsErpApi.listAll().then((response) => {
        if (response.status === "SUCCESS") {
          this.dataSourceList = response.body || [];
          this.dataSourceList.forEach((item) => {
            this.$set(item, "key", item.id);
            this.$set(item, "title", item.name);
          });
        } else {
          this.dataSourceList = [];
        }
      });
    },
    handleUmsUserPmsErpList: function () {
      UmsUserApi.listPmsErpByUmsUserId(this.user.id).then((response) => {
        this.targetKeyList = [];
        if (response.status === "SUCCESS") {
          response.body.forEach((item) => {
            this.targetKeyList.push(item.pmsErpId);
          });
        }
      });
    },
    handleTransferChange: function (nextTargetKeys, direction, moveKeys) {
      if (direction === "right")
        this.handleUmsUserPmsErpRelate(nextTargetKeys, moveKeys);
      if (direction === "left")
        this.handleUmsUserPmsErpRemove(nextTargetKeys, moveKeys);
    },
    handleUmsUserPmsErpRelate: function (nextTargetKeys, moveKeys) {
      UmsUserApi.relatePmsErp(this.user.id, moveKeys).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.targetKeyList = nextTargetKeys;
        }
      });
    },
    handleUmsUserPmsErpRemove: function (nextTargetKeys, moveKeys) {
      UmsUserApi.removePmsErp(this.user.id, moveKeys).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.targetKeyList = nextTargetKeys;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.relate-transfer {
  width: 100%;
}
</style>
